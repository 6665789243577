<template>
  <admin>
    <page-header
      :back="{ to: { name: 'text-blast.index' }, text: 'Text Blast' }"
    >
      <template v-slot:action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="grey"
          class="white--text"
          exact
          @click="downloadLogs"
          :loading="downloading"
          :disabled="downloading"
        >
          <v-icon small left>mdi-cloud-download</v-icon>
          <span v-text="'Download'"></span>
        </v-btn>
      </template>
    </page-header>

    <toolbar-menu
      ref="toolbar"
      :filterDateRange="true"
      :isSearchColsAuto="true"
      :items.sync="tabletoolbar"
      @update:search="setSearch"
      @update:daterangesurvey="setDateRange"
    ></toolbar-menu>

    <v-data-table
      :headers="resources.headers"
      :items="items"
      :options.sync="options"
      :loading="loading"
      :server-items-length="total"
      :items-per-page="5"
    >
      <template v-slot:item.id="{ item }">
        <v-btn text color="primary" icon @click="getDetails(item)">
          {{ item.id }}
        </v-btn>
      </template>

      <template v-slot:item.message="{ item }">
        <small v-if="item.message && item.message.length >= 50">{{
          item.message.substring(0, 50) + "..."
        }}</small>
        <small v-else>{{ item.message }}</small>
      </template>

      <template v-slot:item.answered="{ item }">
        <v-chip label v-if="item.answered === 0">
          <span>no</span>
        </v-chip>
        <v-chip label v-if="item.answered === 1" color="green" text-color="white">
          <span>yes</span>
        </v-chip>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip label v-if="item.status == 'pending'">
          <span>{{ item.status }}</span>
        </v-chip>

        <v-chip label v-if="item.status == 'sent'" color="green" text-color="white">
          <span>{{ item.status }}</span>
        </v-chip>

        <v-chip label v-if="item.status == 'failed'" color="red" text-color="white">
          <span>{{ item.status }}</span>
        </v-chip>
      </template>
    </v-data-table>

    <template v-if="previewDialog">
      <v-dialog
        v-model="previewDialog"
        max-width="900px"
        class="overflow-hidden"
      >
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Survey Result" class="mb-1">#{{ dialogItem.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <!-- Background Details -->
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Mobile" }}
                  </td>
                  <td>
                    {{ dialogItem.mobile }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Survey" }}
                  </td>
                  <td>
                    {{ dialogItem.survey_name }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Text Blast" }}
                  </td>
                  <td>
                    {{ dialogItem.survey_sms_blast_name }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Status" }}
                  </td>
                  <td>
                    <v-chip label v-if="dialogItem.status == 'pending'">
                      <span>{{ dialogItem.status }}</span>
                    </v-chip>

                    <v-chip 
                      label
                      v-if="dialogItem.status == 'sent'"
                      color="green"
                      text-color="white"
                    >
                      <span>{{ dialogItem.status }}</span>
                    </v-chip>

                    <v-chip 
                      label
                      v-if="dialogItem.status == 'failed'"
                      color="red"
                      text-color="white"
                    >
                      <span>{{ dialogItem.status }}</span>
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Answered" }}
                  </td>
                  <td>
                    <v-chip label v-if="dialogItem.answered === 0">
                      <span>no</span>
                    </v-chip>
                    <v-chip 
                      label
                      v-if="dialogItem.answered === 1"
                      color="green"
                      text-color="white"
                    >
                      <span>yes</span>
                    </v-chip>
                    <small v-if="dialogItem.answered === 1"> &nbsp;&nbsp; {{ dialogItem.answered_at }} </small>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Date Created" }}
                  </td>
                  <td>
                    {{ dialogItem.created_at }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="pb-2">
                    <span class="font-weight-bold">{{ "Message" }}</span
                    ><br />
                    <small>{{ dialogItem.message }}</small>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td class="pt-2" colspan="2">
                    <span class="font-weight-bold">{{ "API Response" }}</span
                    ><br />
                    <code
                      ><small>{{ dialogItem.api_response }}</small></code
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- Background Details -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="previewDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </admin>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      options: {},
      dialogItem: null,
      downloading: false,
      previewDialog: false,
      total: 0,
      loading: true,
      searchString: "",
      dateRange: [],
      tabletoolbar: {
        isSearching: false,
        search: null,
        toggleBulkEdit: false,
        bulkCount: 0,
      },
      resources: {
        loading: false,
        headers: [
          {
            text: "ID",
            align: "left",
            value: "id",
          },
          {
            text: "Mobile",
            align: "left",
            value: "mobile",
            sortable: false,
          },
          {
            text: "Message",
            align: "left",
            value: "message",
            sortable: false,
          },
          {
            text: "Answered",
            align: "center",
            value: "answered",
            sortable: true,
          },
          {
            text: "Status",
            align: "center",
            value: "status",
          },
          {
            text: "Date Created",
            align: "left",
            value: "created_at",
          },
        ],
        options: {
          page: 1,
          itemsPerPage: 5,
        },
      },
      id: 0,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      textBlasts: "survey/GET_TEXTBLAST_LOGS",
    }),
  },
  watch: {
    options: {
      handler() {
        const self = this;
        let id = self.$route.params.id,
          q = "",
          date = [];

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let orderBy = sortBy?.length ? sortBy[0] : "created_at";

        //Rename answered column
        if (orderBy == 'answered') {
          orderBy = 'recipients.answered';
        }

        const sortDir = sortDesc && !sortDesc[0] ? "DESC" : "ASC";
        self.getItems(id, page, itemsPerPage, q, date, orderBy, sortDir);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/show",
      getTextBlastLogs: "survey/getTextBlastLogs",
      downloadTextBlastLogs: "survey/downloadTextBlastLogs",
    }),
    setSearch: debounce(async function (e) {
      const self = this;

      let id = self.$route.params.id,
        q = e.target.value,
        date = self.dateRange;

      self.searchString = q;

      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy?.length ? sortBy[0] : "created_at";
      const sortDir = sortDesc && !sortDesc[0] ? "DESC" : "ASC";

      self.getItems(id, page, itemsPerPage, q, date, orderBy, sortDir);
    }, 300),
    getDetails(item) {
      this.dialogItem = item;
      this.previewDialog = true;
    },
    downloadLogs() {
      const self = this;
      let id = self.$route.params.id,
        date = self.dateRange,
        q = self.searchString;

      self.downloading = true;

      self.downloadTextBlastLogs({ id, q, date }).then(function (data) {
        self.downloading = false;
        window.location = data.data.url;
      });
    },

    setDateRange: debounce(async function (e) {
      let startDate = e[0],
        endDate = e[1];

      const self = this;

      let id = self.$route.params.id,
        q = self.searchString,
        date = [startDate, endDate];

      self.dateRange = date;

      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      const orderBy = sortBy?.length ? sortBy[0] : "created_at";
      const sortDir = sortDesc && !sortDesc[0] ? "DESC" : "ASC";

      self.getItems(id, page, itemsPerPage, q, date, orderBy, sortDir);
    }, 300),
    getItems(
      id,
      page,
      itemsPerPage,
      q = "",
      date = [],
      orderBy = "created_at",
      sortDir = "DESC"
    ) {
      const self = this;

      this.loading = true;

      this.getTextBlastLogs({
        survey_sms_blast_id: id,
        page: page,
        per_page: itemsPerPage,
        q: q,
        date: date,
        order_by: orderBy,
        sort: sortDir,
      }).then((data) => {
        self.loading = false;

        self.items = this.textBlasts.data;
        // this.resources.total = this.surveys.meta.total;
        this.$refs.toolbar.items.isSearching = false;

        self.total = this.textBlasts.meta.total;
      });
    },
  },
  async mounted() {},
};
</script>